import { useEffect } from "react";
import { ListSkeleton } from "../../skeletons/List";
import axios from "axios";
import { useState } from "react";
import DataTable from "../../components/Table";
import { Button, Container, TextField, Typography } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import store, { clear, setFieldState } from "../../store/formStore";
import { useSelector } from "react-redux";
import GzipHelper from "../../controllers/GzipHelper";
import Lottie from "lottie-react";
import wipAnimation from "./wip.json";
import Grid from '@mui/material/Grid'; // Grid version 1
import Dropzone from "react-dropzone";
import { AuthHelper } from "../../controllers/AuthHelper";
import { config, S3} from 'aws-sdk';
import { ServerConfig } from '../../../connectors/Config';
import dayjs from "dayjs";
import {Buffer} from 'buffer';
import { LoadingButton } from "@mui/lab";
const Centro = () => {
    const [certificates, setCertificates] = useState([]);
    const [loading, setLoading] = useState(false);
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();
    const {builder, form, refs} = useBuilder();

    const elements = useSelector(state => state.elements);

    const columns = [
        { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header' },
        { field: 'name', headerName: 'Nome e cognome', width: 200, headerClassName: 'data-grid-header' },
       
    ];

    const [centro, setCentro] = useState({
        logo: "",
        name: "",
        url: "",
        description: "",
        sede_legale: "",
        indirizzo_scuola: "",
        piva: "",
        email: "",
        telefono: ""
    })

    const [gallery, setGallery] = useState([]);

    const getCertificates = async() => {
        const res = await (await fetch('https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/teachers')).json();
        setCertificates([...res]);
        setLoading(false);
    }
    useEffect(() => {
        getCertificates();
        store.dispatch(clear())
        builder('certificates.add');
    }, [])

    const handleClick = (data, name = null) => {
        store.dispatch(clear())
        builder('teachers.add');
        if(data.row){
            Object.keys(data.row).forEach((key) => {
                store.dispatch(setFieldState({name: key, value: data.row[key]}));
            });
        }
        pushMobileOpen(true, 500, name ? name : "Modifica Docente", data.row);

    }
    const fileConverterBase64 = (file) => {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                res(reader.result)
            }
            reader.onerror = (err) => {
                rej(err)
            }
        })
    }
    
    const saveSchool = async() => {
        setLoading(true);
        const user = await AuthHelper.getUserFromIdToken();
        
        try{
            const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/school/${user.Username}`, {
                method: 'PUT',
                body: JSON.stringify({...centro, gallery: gallery})
            });
            alert("Dati anagrafici salvati con successo")
            setLoading(false);
        }catch(ex){
            alert("Errore server");
            setLoading(false);
        }
    }

    const getSchool = async() => {
        const user = await AuthHelper.getUserFromIdToken();
        try{
            const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/school/${user.Username}`);
            const json = await res.json();
            console.log(json.gallery.map(x => x.img_url));
            setCentro({...json.school})
            setGallery([...json.gallery.map(x => x.img_url)]);
        }catch(ex){
            alert("Errore server");
            console.log(ex);
        }
    }

    useEffect(() => {
        getSchool();
    }, [])
    const logoUploader = async(e) => {
        setLoading(true);

        config.update({
            Region: ServerConfig.AWS.s3Bucket.Region, 
            credentials: {
                accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
                secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
            }});
        const s3 = new S3({apiVersion: ServerConfig.AWS.s3Bucket.ApiVersion, credentials: {
            accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
            secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
        }});
        try{
            const fileBase64 = await fileConverterBase64(e[0]);
            const fileParticles = fileBase64.split(',')[0];
            const fileName = `${dayjs().valueOf()}.document-file.${fileParticles.split(';')[0].split('/')[1]}`
            const params = {
                Bucket: ServerConfig.AWS.s3Bucket.BucketName,
                Key: fileName,
                Body: Buffer.from(fileBase64.split(',')[1], 'base64'),
                ContentType: e[0].type,
                ACL: 'public-read'
            };
            const fileRes = await s3.putObject(params).promise();
           
            setCentro({...centro, logo: `https://new-mase.s3.eu-west-1.amazonaws.com/${fileName}`});
            setLoading(false);
        }catch(ex){
            console.log(ex);
            alert('Errore server')
        }
    }

    const galleryUploader = async(e) =>  {
        setLoading(true);

        config.update({
            Region: ServerConfig.AWS.s3Bucket.Region, 
            credentials: {
                accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
                secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
            }});
        const s3 = new S3({apiVersion: ServerConfig.AWS.s3Bucket.ApiVersion, credentials: {
            accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
            secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
        }});
        try{
            const fileBase64 = await fileConverterBase64(e[0]);
            const fileParticles = fileBase64.split(',')[0];
            const fileName = `${dayjs().valueOf()}.document-file.${fileParticles.split(';')[0].split('/')[1]}`
            const params = {
                Bucket: ServerConfig.AWS.s3Bucket.BucketName,
                Key: fileName,
                Body: Buffer.from(fileBase64.split(',')[1], 'base64'),
                ContentType: e[0].type,
                ACL: 'public-read'
            };
            const fileRes = await s3.putObject(params).promise();
            console.log(`https://new-mase.s3.eu-west-1.amazonaws.com/${fileName}`);
            setGallery([...gallery, `https://new-mase.s3.eu-west-1.amazonaws.com/${fileName}`]);
            console.log(gallery);
            setLoading(false);
        }catch(ex){
            console.log(ex);
            alert('Errore server')
        }
    }
    return (<>
        <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Container sx={{mt: 6}}>
                <Grid container spacing={2}>
                    <Grid xs={12} sx={{textAlign: 'left', mb: 3}}>
                        <h2>Logo Centro</h2>
                        {centro.logo === "" || !centro.logo ? <Dropzone onDrop={acceptedFiles => logoUploader(acceptedFiles)}>
                            {({getRootProps, getInputProps}) => (
                                <section style={{width: '100%', padding: 50, border: '1px solid #000', textAlign: 'center'}}>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>Trascina o clicca per caricare il tuo logo</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone> : <img src={centro.logo} style={{width: 300}}/>}
                        
                    </Grid>
                    <Grid xs={12} sx={{textAlign: 'left', mb: 3}}>
                        <h2>Gallery Centro</h2>
                        {<Dropzone multiple onDrop={acceptedFiles => galleryUploader(acceptedFiles)}>
                            {({getRootProps, getInputProps}) => (
                                <section style={{width: '100%', padding: 50, border: '1px solid #000', textAlign: 'center'}}>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>Trascina o clicca per caricare immagini nella gallery</p>
                                        <div style={{display: 'flex', flexDirection: 'row', gap: 8, flexWrap: 'wrap'}}>
                                            {gallery?.map(img => (<img style={{width: 200, height: 200, objectFit: 'cover'}} src={img} />))}
                                        </div>
                                        
                                    </div>
                                </section>
                            )}
                        </Dropzone>}
                        
                    </Grid>
                    <Grid xs={6} sx={{mr: 12, mb: 3}}>
                        <TextField fullWidth id="outlined-basic" label="Nome Centro" value={centro.name} variant="outlined" onChange={(e) => setCentro({...centro, name: e.target.value})}/>
                    </Grid>
                    <Grid xs={5}>
                        <TextField fullWidth id="outlined-basic" label="Link al sito web" value={centro.url} variant="outlined" onChange={(e) => setCentro({...centro, url: e.target.value})}/>
                    </Grid>
                    <Grid xs={6} sx={{mr: 12, mb: 3}}>
                        <TextField fullWidth id="outlined-basic" label="E-mail" variant="outlined" value={centro.email} onChange={(e) => setCentro({...centro, email: e.target.value})}/>
                    </Grid>
                    <Grid xs={5}>
                        <TextField fullWidth id="outlined-basic" label="Telefono" variant="outlined" value={centro.telefono} onChange={(e) => setCentro({...centro, telefono: e.target.value})}/>
                    </Grid>
                    <Grid xs={6} sx={{mr: 12, mb: 3}}>
                        <TextField fullWidth id="outlined-basic" label="Sede Legale" variant="outlined" value={centro.sede_legale} onChange={(e) => setCentro({...centro, sede_legale: e.target.value})}/>
                    </Grid>
                    <Grid xs={5}>
                        <TextField fullWidth id="outlined-basic" label="Indirizzo scuola" variant="outlined" value={centro.indirizzo_scuola} onChange={(e) => setCentro({...centro, indirizzo_scuola: e.target.value})}/>
                    </Grid>
                    <Grid xs={6} sx={{mr: 12, mb: 3}}>
                        <TextField fullWidth id="outlined-basic" label="Partita IVA" variant="outlined" value={centro.piva} onChange={(e) => setCentro({...centro, piva: e.target.value})}/>
                    </Grid>
                    <Grid xs={12}>
                        <TextField
                            fullWidth
                            id="standard-multiline-static"
                            label="Descrizione centro formazione"
                            multiline
                            rows={4}
                            defaultValue=""
                            variant="outlined"
                            onChange={(e) => setCentro({...centro, description: e.target.value})}
                        />
                    </Grid>
                    <Grid xs={10}></Grid>
                    <Grid xs={2} sx={{mt: 3}}><LoadingButton loading={loading} fullWidth variant="contained" onClick={() => saveSchool()}>Salva dati</LoadingButton></Grid>
                </Grid>
            </Container>
            
        
        </div>
    </>);
}

export default Centro;