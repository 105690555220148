const ServerConfig = {
    AWS: {
        secret: "g8UqQ02s/ADDojhedIBtqK0BQRwBNn0U4zKtLPgq",
        accessKey: "AKIARDOLO7ON2QR5MLHS",
        s3Bucket: {
            Region: 'eu-west-1',
            BucketName: 'new-mase',
            ApiVersion: '2006-03-01',
            AccessKeyId: 'AKIAWBCKWXONLAGCSRK2',
            SecretKeyId: 'kAfPlH7igrCIIfHf2ScDGUEh+zOyAhuQwEOELVZl'
        },
        Cognito: {
            Region: "eu-west-1",
            UserPoolId: "eu-west-1_jcuGkzHHO",
            ClientId: "2ip8mpd057qpsr7egntj8d7jq4",
        }
    },
    authTimeout: 18000,
    platformName: 'MASE',
}

export { ServerConfig };