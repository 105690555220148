import { useEffect } from "react";
import { ListSkeleton } from "../../skeletons/List";
import { useState } from "react";
import { Button, Container, Typography, Box, Tab, Tabs, Rating, IconButton, Menu, MenuItem, Modal } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import store, { clear, setFieldState } from "../../store/formStore";
import { useSelector } from "react-redux";
import ListTeachers from "../teachers/ListTeachers";
import ListAule from "../aule/ListAule";
import ListDirettori from "../direttore/ListDirettori";

import DataTable from "../../components/Table";
import moment from "moment";
import { AuthHelper } from "../../controllers/AuthHelper";
import { DeleteForever, MoreHoriz, People } from "@mui/icons-material";
import { SimpleDialog } from "./CourseDetail";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1280,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
const ListDigital = () => {
    const [certificates, setCertificates] = useState([]);
    const [loading, setLoading] = useState(false);
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();
    const {builder, form, refs} = useBuilder();
    const [value, setValue] = useState(0);
    const [recensioni, setReviews] = useState([]);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const findReviews = async() => {
      const user = await AuthHelper.getUserFromIdToken();

      const res = await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/courses/digital`);
      const json = await res.json();
      setReviews([...json]);
    }
    const elements = useSelector(state => state.elements);

    
    const columns = [
        { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header'},
        { field: 'type', headerName: 'Tipo', width: 200, headerClassName: 'data-grid-header' },
        { field: 'title', headerName: 'Titolo', width: 200, headerClassName: 'data-grid-header' },
        { field: 'role', headerName: 'Ruolo', width: 200, headerClassName: 'data-grid-header'},
        { field: 'description', headerName: 'Descrizione', width: 200, headerClassName: 'data-grid-header'},
        { field: 'actions', headerName: 'Azioni', width: 100, headerClassName: 'data-grid-header', renderCell: (_params, idx) => (
            <><IconButton size="small" onClick={async() => {
              await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/courses/digital/${_params.id}`, {
                method: 'DELETE'
              });
              await findReviews();
            }}><DeleteForever htmlColor="#DD0000" /></IconButton>
            <IconButton size="small" onClick={async() => {
              console.log(_params);
              await getPartecipants(_params.id);
            }}><People htmlColor="#000" /></IconButton>
            </>
          )}
    ];
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const [partecipants, setPartecipants] = useState(null);

    const _handleClick2 = (event) => {
      setAnchorEl2(event.currentTarget);
    }
    const _handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose2 = () => {
      setAnchorEl2(null);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    useEffect(() => {
        findReviews();
        store.dispatch(clear())
        builder('promo.add');
    }, [])
    const getPartecipants = async(id) => {
      const user = await AuthHelper.getUserFromIdToken();
      const res = await (await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/partecipants/${id}`)).json();
      console.log(res);
      setPartecipants([...res]);
    }
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogDatesOpen, setDialogDatesOpen] = useState(false);
    const handleClickDatesOpen = () => {
      setDialogDatesOpen(true);
    };
    const handleCloseDatesDialog = (value) => {
      setDialogDatesOpen(false);
      getPartecipants();
    };
    const handleClickOpen = () => {
      setDialogOpen(true);
    };
  
    const handleCloseDialog = (value) => {
      setDialogOpen(false);
    };
    
    const [modal, setModal] = useState(false);

    useEffect(() => {
      console.log(partecipants);
      if(partecipants){
        setModal(true);
      }
    }, [partecipants]);

    useEffect(() => {
      console.log(modal);
    }, [modal])
    const partecipantsColumns = [
      { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header' },
      { field: 'avatar', headerName: 'Foto', width: 125, headerClassName: 'data-grid-header', renderCell: (params) => {
        return (
        <img src={params.row.avatar} style={{width: 85, height: 85, objectFit: 'cover', borderRadius: '100%'}} />
      ) }},
      { field: 'first_name', headerName: 'Nome', width: 200, headerClassName: 'data-grid-header' },
      { field: 'last_name', headerName: 'Cognome', width: 200, headerClassName: 'data-grid-header' },
      { field: 'documents', headerName: 'Documentazione', width: 200, headerClassName: 'data-grid-header', renderCell: (params) => (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={_handleClick}
          >
                <MoreHoriz></MoreHoriz>
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '20ch',
              },
            }}
          >
            {params.row.documents.map(x => (
              <MenuItem onClick={() => {
                if(!x.file){
                  alert("L'utente deve caricare il PDF");
                  return;
                }
                window.open(x.file);
              }}>{x.name}</MenuItem>
            ))}
          </Menu>
        </div>
      )},
      { field: 'actions', headerName: 'Azioni', width: 225, headerClassName: 'data-grid-header', renderCell: (_params) => (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open2 ? 'long-menu' : undefined}
            aria-expanded={open2 ? 'true' : undefined}
            aria-haspopup="true"
            onClick={_handleClick2}
          >
                <MoreHoriz></MoreHoriz>
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose2}
            PaperProps={{
              style: {
                width: '20ch',
              },
            }}
          >
            <MenuItem onClick={() => handleClickOpen()}>Rivedi documentazione</MenuItem>
          </Menu>
          <SimpleDialog
            open={dialogOpen}
            files={_params.row.documents}
            onClose={handleCloseDialog}
            params={_params}
          />
         
        {/* <Button onClick={async() => {
          const urlParams = new URLSearchParams(window.location.search);
          const course = urlParams.get('nome_corso');
          await savePdf(course, lessons, `${params.row.first_name} ${params.row.last_name}`);
        }}>Stampa Libretto</Button> */}
       
        {/* <Button disabled onClick={async() => {
        
        }}>Rivedi documentazione</Button> */}
        </div>
      )}
  ]

    const handleClick = (data, name = null) => {
        store.dispatch(clear())
        builder('courses.add');
        if(data.row){
            Object.keys(data.row).forEach((key) => {
                store.dispatch(setFieldState({name: key, value: data.row[key]}));
            });
        }
        pushMobileOpen(true, 500, name ? name : "Crea Domanda", data.row);

    }

    return (<Container sx={{paddingLeft: 24, paddingRight: 24, minWidth: '100vw', marginTop: '3rem'}}>
       <Modal
            open={modal}
            onClose={() => setModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Lista partecipanti corso digitale
              </Typography>
              <DataTable data={[...new Set(partecipants)]} columns={partecipantsColumns} handleClick={handleClick} actions={[
              {type: 'select', name: 'Azioni'}
              ]}/>
            </Box>
          </Modal>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Lista corsi digitali" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {loading && <ListSkeleton />}
        {/* {!loading && <DataTable data={certificates} columns={columns} handleClick={handleClick} actions={[
            {type: 'select', name: 'Azioni'}
        ]}/>} */}
        {!loading && <DataTable data={recensioni} columns={columns} actions={[
        {type: 'select', name: 'Azioni'}
        ]}/>}
            <FloatingActionButtons onClick={() => handleClick([], "Crea Domanda")}/>

      </CustomTabPanel>
   
    
    </Container>);
}

export default ListDigital;